import axios from 'axios'
import {BACKEND_URL} from "./config";
import {mapSegments} from './segments/segments';

export const bareRequest = async (text: string) => {
    const data = await axios.get(BACKEND_URL + 'bare-request?text=' + text.replaceAll('\n', '%0A'));
    return data.data.answer;
}

export const signUp = async (privyId: string, email: string, source: string) => {
    const data = await axios.post(BACKEND_URL + 'users/signup/', {username: email, password: privyId, source: source});
    return data.data.token;
}

export const sendRequest = async (text: string, deepScan: boolean) => {
    try {
        const data = await axios.post(
            BACKEND_URL + 'text',
            {'text': text, "deep_scan": deepScan},
            {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token')
                }
            }
        );
        var segments: number[] = data.data.segmentation_tokens;
        return {
            "answer": data.data.answer.toFixed(2),
            "error": null,
            "segments": deepScan ? mapSegments(text, segments) : null,
            "id": data.data.id
        };
    } catch (e) {
        return {
            "answer": null,
            "error": (e as any).response.data.error,
            "segments": null
        }
    }

}

const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
}

export const utmRequest = async (options: object) => {
    if (isAuthenticated()) {
        return await sendEventRequest("utm-app", options)
    } else {
        return await axios.post(BACKEND_URL + 'utm', {event: "utm-app", options: options});
    }
}

const request = async (endpoint: string, data: object) => {
    return await axios.post(
        BACKEND_URL + endpoint,
        data,
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
}

export const britishizeRequest = async (text: string) => {
    try {
        const response = await request("britishize", {"text": text})
        return {
            "error": null,
            "text": response.data.text,
        };
    } catch (e) {
        return {
            "error": (e as any).response?.data?.error || "Server error",
            "text": ""
        }
    }

}

export const sendFileRequest = async (file: File) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
        const data = await axios.post(
            BACKEND_URL + 'text',
            bodyFormData,
            {
                headers: {
                    'Authorization': 'Token ' + localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data"
                }
            }
        );
        return data.data;

    } catch (e) {
        return (e as any).response.data.error[0]
    }

}

export const createAPIKey = async (name: string) => {
    if (!name.length) name = '-';
    var data = await axios.post(
        BACKEND_URL + 'key', {name: name},
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const getApiKeys = async () => {
    var data = await axios.get(
        BACKEND_URL + 'key',
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}


export const deleteAPIKey = async (key: string) => {
    var data = await axios.delete(
        BACKEND_URL + 'key/' + key,
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}


export const getToken = async (privyId: string, email: string, source: string) => {
    try {
        const response = await axios.post(BACKEND_URL + 'auth-token/', {
            username: email,
            password: privyId
        }, {headers: {"Content-Type": 'application/json'}});
        return response.data;
    } catch {
        return {token: await signUp(privyId, email, source)};
    }
}


export const updateAPIKey = async (key: string, title: string) => {
    var data = await axios.patch(
        BACKEND_URL + 'key/' + key,
        {
            name: title
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}


export const getWordsUsed = async () => {
    const token = localStorage.getItem('token');
    if (!token) return 'error';
    try {
        var data = await axios.get(
            BACKEND_URL + 'users/me/',
            {
                headers: {
                    'Authorization': 'Token ' + token
                }
            }
        );
        console.log(localStorage.getItem('token'));
        localStorage.setItem('id', data.data.id);
        return data.data;
    } catch (e) {
        console.log(e);
        return 'error'
    }

}


export const getAnalytics = async (trashold: number) => {
    var data = await axios.post(
        BACKEND_URL + 'analitycs',
        {
            trashold
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const sendSubscribeRequest = async (email: string, name: string, is_free: boolean) => {
    var data = await axios.post(
        BACKEND_URL + 'subscribe-request',
        {
            email,
            name,
            is_free
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    )
    return data.data;
}

export const sendEventRequest = async (event: string, options = {}) => {
    const token = localStorage.getItem('token');
    if (!token) return 'error';
    var data = await axios.post(
        BACKEND_URL + 'events',
        {
            event: event,
            options: options
        },
        {
            headers: {
                'Authorization': 'Token ' + token
            }
        }
    )
    return data.data;
}

export const mapAnonUser = async (anonUsername: string) => {
    var data = await axios.post(
        BACKEND_URL + 'users/map_anon_user/',
        {
            anon_username: anonUsername
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}


export const getHistoryRequest = async (page: number, pageSize: number) => {
    var data = await axios.get(BACKEND_URL + 'text?page=' + page + "&page_size=" + pageSize, {
        headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
        }
    });
    return data.data;
}

export const getFeedback = async (id: string, feedback: 'positive' | 'negative') => {
    var data = await axios.post(
        BACKEND_URL + `feedback/${id}`,
        {
            feedback: feedback
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}

export const getUserByHeader = async () => {
    const data = await axios.get(
        BACKEND_URL + 'users/get_user_by_header/',
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
    return data.data;
}
